define('uti-admin/components/modal-window', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      ok: function ok() {
        this.get('model').save();
        this.$('.modal').modal('hide');
      }
    },
    show: (function () {
      this.$('.modal').modal().on('hidden.bs.modal', (function () {
        this.get('model').rollbackAttributes();
        this.sendAction('removeModal');
      }).bind(this));
    }).on('didInsertElement')
  });
});
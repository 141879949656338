define('uti-admin/routes/profiles/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

		model: function model() {
			return _ember['default'].A([{ label: 'ID', cellComponent: 'tables/main-info' }, { label: 'Контакты', cellComponent: 'tables/contacts-list' }, { label: 'Данные', cellComponent: 'tables/status-list' }, { label: 'Пользователи', valuePath: 'users', cellComponent: 'tables/hasmany-cell' }, { label: '', cellComponent: 'tables/table-link', width: '40px' }]);
		},

		setupController: function setupController(controller, model) {
			controller.set('model', model);
			this.store.findAll('profiletag').then(function (d) {
				controller.set('tags', d);
			});
		},

		actions: {
			goTo: function goTo(model) {
				this.transitionTo('profiles.edit', model);
			}
		}
	});
});
define('uti-admin/models/tag', ['exports', 'ember-data'], function (exports, _emberData) {
   exports['default'] = _emberData['default'].Model.extend({
      title: _emberData['default'].attr('string'),
      header: _emberData['default'].attr('string'),
      description: _emberData['default'].attr('string'),
      color: _emberData['default'].attr('string'),
      outlet: 'modals/tag',
      translite: _emberData['default'].attr('string'),
      isMain: _emberData['default'].attr('boolean')
   });
});
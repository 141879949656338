define('uti-admin/routes/support/faq/view', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

		model: function model(params) {
			return this.store.query('faq', params.faq_id);
		},
		setupController: function setupController(controller, model) {
			controller.set('model', model);
			this.controllerFor('application').set('title', 'FAQ');
		}
	});
});
define('uti-admin/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
		actions: {
			showModal: function showModal(name, model, modelName) {
				this.render(name, {
					into: 'application',
					outlet: 'modal',
					model: model ? model : this.store.createRecord(modelName)
				});
			},

			removeModal: function removeModal() {
				this.disconnectOutlet({
					outlet: 'modal',
					parentView: 'application'
				});
			},

			'delete': function _delete(model) {
				var _this2 = this;

				if (window.confirm("Элемент будет удален безвозвратно, продолжить?")) {
					var _this;

					(function () {
						_this = _this2;

						var flashMessages = _ember['default'].get(_this2, 'flashMessages');
						model.set('isDelete', true);

						model.save().then(function () {
							model.deleteRecord();
							flashMessages.success('Изменения сохранены!');
						})['catch'](function (err) {
							flashMessages.danger('Что-то пошло не так :(');
						});
					})();
				}
			}
		}
	});
});
define("uti-admin/templates/components/tables/destroy-rec", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 62
          }
        },
        "moduleName": "uti-admin/templates/components/tables/destroy-rec.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("i");
        dom.setAttribute(el1, "class", "fa fa-trash tableIcon");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(1);
        morphs[0] = dom.createElementMorph(element0);
        return morphs;
      },
      statements: [["element", "action", ["destroy", ["get", "row", ["loc", [null, [1, 52], [1, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 33], [1, 57]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});
define('uti-admin/components/tables/hasmany-cell', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		store: _ember['default'].inject.service(),
		users: null,
		didInsertElement: function didInsertElement() {
			var profile = this.get('row.id');
			var that = this;
			this.get('store').query('user', { profile: profile }).then(function (u) {
				that.set('users', u);
			});
		}
	});
});
define('uti-admin/routes/support/chat/view', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

		model: function model(params) {
			return this.store.findRecord('support-case', params.chat_id);
		},

		actions: {
			ask: function ask(scase) {
				var _this = this.get('controller');
				scase.set('answered', true);

				var question = _this.store.createRecord('support', {
					body: _this.get('question'),
					isAnswer: true,
					isWatch: false,
					supportInform: scase.get('profile')
				});

				scase.get('messages').pushObject(question);
				question.save().then(function () {
					scase.save();
				});
				_this.set('question', '');
			},

			close: function close(scase) {
				scase.set('closed', true);
				scase.save();
				this.transitionTo('support.chat');
			}
		}
	});
});
define('uti-admin/models/questroom', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    description_en: _emberData['default'].attr('string'),
    type: _emberData['default'].attr('string'),
    title_en: _emberData['default'].attr('string'),
    maxDays: _emberData['default'].attr('number', { defaultValue: 12 }),
    img: _emberData['default'].attr('string'),
    logo: _emberData['default'].attr('string'),
    isActive: _emberData['default'].attr('boolean'),
    playersMin: _emberData['default'].attr('number', { defaultValue: 2 }),
    playersMax: _emberData['default'].attr('number', { defaultValue: 5 }),
    style: _emberData['default'].attr('string', { defaultValue: 'darkStyle' }),
    playersMaxi: _emberData['default'].attr('number'),
    actors: _emberData['default'].attr('boolean', { defaultValue: false }),
    isIncrease: _emberData['default'].attr('boolean', { defaultValue: false }),
    increase: _emberData['default'].attr('number'),
    telephone: _emberData['default'].attr('string'),
    adress: _emberData['default'].belongsTo('adr', { async: true }),

    level: _emberData['default'].attr('string', { defaultValue: 'Средний' }),
    time: _emberData['default'].attr('number', { defaultValue: 60 }),
    isDelete: _emberData['default'].attr('boolean')
  });
});
define('uti-admin/models/promocode', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    createdAt: _emberData['default'].attr('date'),
    code: _emberData['default'].attr('string'),
    cost: _emberData['default'].attr('string'),
    isUsed: _emberData['default'].attr('string', { defaultValue: false }),
    formatDate: (function () {
      return moment(this.get('createdAt')).locale('ru').calendar();
    }).property('createdAt')
  });
});
define('uti-admin/models/profile', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    title: _emberData['default'].attr('string'),
    lastVisit: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('string'),
    telephone: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    vk: _emberData['default'].attr('string'),
    site: _emberData['default'].attr('string'),
    smsName: _emberData['default'].attr('string'),
    adr: _emberData['default'].hasMany('adr', { async: true }),

    telegramToken: _emberData['default'].attr('number'),
    telegramId: _emberData['default'].attr('string'),

    city: _emberData['default'].attr('string'),
    priceZone: _emberData['default'].attr('string'),
    timeZone: _emberData['default'].attr('string'),

    startTime: _emberData['default'].attr('string', { defaultValue: '12:00' }),
    endTime: _emberData['default'].attr('string', { defaultValue: '00:00' }),

    //оповещения
    support: _emberData['default'].hasMany('support', { async: true }),
    updates: _emberData['default'].hasMany('update', { async: true }), // обновления системы

    //начальные настройки системы
    needSettings: _emberData['default'].attr('boolean', { defaultValue: false }),
    needConfirm: _emberData['default'].attr('boolean', { defaultValue: false }),

    //счетчики
    visitsCount: _emberData['default'].attr('number'),
    receiptCount: _emberData['default'].attr('number'),
    movingCount: _emberData['default'].attr('number'),
    expenseCount: _emberData['default'].attr('number'),

    //баланс
    keys: _emberData['default'].attr('number'),
    sms: _emberData['default'].attr('number'),

    //оповещения
    telToSms: _emberData['default'].attr('string'),
    isNewVisitAdmin: _emberData['default'].attr('boolean'), //оповещения для админа интеграция
    isSmsDelAdm: _emberData['default'].attr('boolean'), //отмена визита админу
    isNewVisitClientInt: _emberData['default'].attr('boolean'), //оповещение для клиента запись через виджет
    isNewVisitClientSite: _emberData['default'].attr('boolean'), //оповещение для клиента запись  через сайт
    //отправляем пока что только из виджета
    isRemember: _emberData['default'].attr('boolean'),
    isIntegrate: _emberData['default'].attr('boolean', { defaultValue: false })

  });
});
define('uti-admin/routes/clients', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

		model: function model() {
			return _ember['default'].A([{ label: 'ID', valuePath: 'id' }, { label: 'Имя', valuePath: 'title' }, { label: 'Телефон', valuePath: 'telephone' }, { label: 'Почта', valuePath: 'email' }, { label: 'VK', valuePath: 'vk' }, { label: 'Код', valuePath: 'code' }, { label: 'Визиты', valuePath: 'visits', cellComponent: 'tables/hasmany-cell2' }, { label: 'Избранное', valuePath: 'favorites', cellComponent: 'tables/hasmany-cell' }, { label: '', cellComponent: 'tables/table-link', width: '40px' }]);
		},
		actions: {
			goTo: function goTo(model) {
				//this.transitionTo('profiles.edit', model)
			}
		}
	});
});
define('uti-admin/components/tables/table-form', ['exports', 'ember', 'ember-light-table'], function (exports, _ember, _emberLightTable) {
    var isEmpty = _ember['default'].isEmpty;
    var computed = _ember['default'].computed;
    exports['default'] = _ember['default'].Component.extend({
        skip: -10,
        limit: 10,
        sort: 'createdAt DESC',
        table: null,
        records: null,
        isLoading: false,
        canLoadMore: true,

        store: _ember['default'].inject.service(),

        columns: computed(function () {
            return this.get('tableRows');
        }),

        init: function init() {
            this._super.apply(this, arguments);
            this.set('table', new _emberLightTable['default'](this.get('columns')));
        },

        fetchRecords: function fetchRecords() {
            var _this = this;

            var params = this.get('params');
            var prop = this.getProperties(['skip', 'limit', 'sort']);

            if (params) Object.assign(prop, params);

            this.set('isLoading', true);

            this.get('store').query(this.get('modelName'), prop).then(function (records) {
                _this.get('table').addRows(records);

                if (params) {
                    _this.set('records', records);
                }
                if (!params) {
                    _this.set('records', []);
                }

                _this.set('isLoading', false);
                _this.set('canLoadMore', !isEmpty(records));
            });
        },

        actions: {
            onScrolledToBottom: function onScrolledToBottom() {
                if (this.get('canLoadMore') && this.get('limit') !== 10000) {
                    this.incrementProperty('skip', 10);
                    this.fetchRecords();
                }
            },

            showModal: function showModal(p, m, modelName, id) {
                this.sendAction('showModal', p, m, modelName, id);
            },

            goTo: function goTo(model) {
                this.sendAction('action', model.get('content'));
            },

            search: function search(val) {
                this.set('skip', 0);
                var q = {
                    or: [{ title: { contains: val } }, { code: { contains: val } }, { description: { contains: val } }]
                };
                this.set('params', val ? q : false);
                this.get('table').setRows([]);
                this.fetchRecords();
            },

            onRowClick: function onRowClick(model) {
                this.sendAction('action', model.get('content'));
            },

            custom: function custom(model) {
                this.sendAction('action', model);
            },

            deleteModel: function deleteModel(model) {
                var that = this;
                $.confirm({
                    title: '<i class="fa fa-trash alertIcon"></i> Удаление элемента',
                    content: 'Вы действительно хотите удалить это?',
                    confirm: function confirm() {
                        model.get('content').set('isDelete', true);
                        model.get('content').save();
                        that.get('table').removeRow(model);
                    }
                });
            },

            destroyModel: function destroyModel(model) {
                var that = this;
                $.confirm({
                    title: '<i class="fa fa-trash alertIcon"></i> Удаление элемента',
                    content: 'Вы действительно хотите удалить это?',
                    confirm: function confirm() {
                        model.get('content').destroyRecord();
                        that.get('table').removeRow(model);
                    }
                });
            },

            onColumnClick: function onColumnClick(column) {
                if (column.sorted) {
                    this.setProperties({
                        sort: column.get('valuePath') + (column.ascending ? ' asc' : ' desc'),
                        skip: 0
                    });
                    this.get('table').setRows([]);
                    this.fetchRecords();
                }
            }
        }
    });
});
define('uti-admin/controllers/profiles/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      showResults: function showResults() {

        var queries = {};
        if (this.get('id')) queries.id = this.get('id');
        if (this.get('keys')) queries.keys = { '>=': this.get('keys') };
        if (this.get('payments')) queries.paymentsCount = { '>=': this.get('payments') };

        var to = this.get('to') || null,
            from = this.get('from') || null,
            cStart = this.get('createFrom') ? moment.utc(this.get('createFrom')).valueOf() : null,
            cEnd = this.get('createTo') ? moment.utc(this.get('createTo')).add(1, 'days').valueOf() : null;

        if (to || from) {
          queries.lastVisit = {};
          if (to) queries.lastVisit['<='] = moment(new Date(to + ' UTC')).utc().add(1, 'days').format();
          if (from) queries.lastVisit['>='] = moment(new Date(from + ' UTC')).utc().format();
        }

        if (cStart || cEnd) {
          queries.createdAt = {};
          if (cStart) queries.createdAt['>='] = cStart;
          if (cEnd) queries.createdAt['<='] = cEnd;
        }

        var params = { where: queries };
        this.set('params', params);
        this.send('refreshList');
        this.set('disabled', true);
        this.get('ajax').request('/visits/findProfileByQueries?' + $.param(queries)).then(function (rsp) {
          this.set('recordsLength', rsp);
          this.set('disabled', false);
        });
      },

      refreshList: function refreshList() {
        var that = this;
        that.set('refresh', true);
        _ember['default'].run.next(function () {
          that.set('refresh', false);
        });
      }
    }

  });
});
define('uti-admin/models/informer', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		roomsLength: _emberData['default'].attr('number'),
		fromProfile: _emberData['default'].attr('boolean'),
		fromUser: _emberData['default'].attr('boolean'),
		fromRoom: _emberData['default'].attr('boolean'),
		keys: _emberData['default'].attr('number'),
		profile: _emberData['default'].belongsTo('profile', { async: true }),
		visitsCount: _emberData['default'].attr('number', { defaultValue: 0 }),
		receiptCount: _emberData['default'].attr('number', { defaultValue: 0 }),
		movingCount: _emberData['default'].attr('number', { defaultValue: 0 }),
		expenseCount: _emberData['default'].attr('number', { defaultValue: 0 }),
		support: _emberData['default'].hasMany('support', { async: true }),
		//	visits: DS.hasMany('visit', {async: true}),
		sms: _emberData['default'].attr('string'),
		telephone: _emberData['default'].attr('string'),
		isSmsAdm: _emberData['default'].attr('boolean'),
		isSmsBalance: _emberData['default'].attr('boolean'),
		isSmsSms: _emberData['default'].attr('boolean'),
		isNewVisit: _emberData['default'].attr('boolean'),
		isChange: _emberData['default'].attr('boolean'),
		isRemember: _emberData['default'].attr('boolean'),
		isSmsDel: _emberData['default'].attr('boolean'),
		isClientBitrh: _emberData['default'].attr('boolean')
	});
});
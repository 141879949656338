define('uti-admin/router', ['exports', 'ember', 'uti-admin/config/environment'], function (exports, _ember, _utiAdminConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _utiAdminConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('login');
    this.route('sms');
    this.route('404', { path: '/*path' });

    this.route('support', function () {
      this.route('faq', function () {
        this.route('view', { path: '/:faq_id' });
      });
      this.route('chat', function () {
        this.route('view', { path: '/:chat_id' });
      });
      this.route('updates', function () {
        this.route('edit', { path: '/:update_id' });
      });
    });
    this.route('profiles', function () {
      this.route('edit', { path: '/:p_id' });
    });
    this.route('codes');
    this.route('payments', function () {
      this.route('account');
      this.route('sms');
    });
    this.route('updates', function () {
      this.route('new');
      this.route('edit', { path: '/:update_id' });
    });
    this.route('tags');
    this.route('cities');
    this.route('clients');
    this.route('profile-tags');
  });

  exports['default'] = Router;
});
define('uti-admin/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        email: _emberData['default'].attr('string'),
        img: _emberData['default'].attr('string'),
        firstName: _emberData['default'].attr('string'),
        lastName: _emberData['default'].attr('string'),
        specialty: _emberData['default'].attr('string'),
        telephone: _emberData['default'].attr('string'),
        vk: _emberData['default'].attr('string'),
        title: (function () {
            var ln = this.get('lastName') ? this.get('lastName') : '';
            return this.get('firstName') + ' ' + ln;
        }).property('firstName', 'lastName')
    });
});
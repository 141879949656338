define('uti-admin/adapters/application', ['exports', 'ember', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'uti-admin/config/environment'], function (exports, _ember, _emberData, _emberSimpleAuthMixinsDataAdapterMixin, _utiAdminConfigEnvironment) {
	exports['default'] = _emberData['default'].RESTAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
		headers: _ember['default'].computed('session', function () {
			return {
				"Authorization": "Bearer " + this.get("session.data.authenticated.access_token")
			};
		}),
		authorizer: 'authorizer:oauth2',
		coalesceFindRequests: true,
		host: _utiAdminConfigEnvironment['default'].host,
		shouldReloadAll: function shouldReloadAll(store, snapshotArray) {
			/*var snapshots = snapshotArray.snapshots();
    return snapshots.any(function(ticketSnapshot) {
     var timeDiff = moment().diff(ticketSnapshot.attr('lastAccessedAt')).minutes();
     if (timeDiff > 10) {
       return true;
     } else {
       return false;
     }
   });*/
		}
	});
});
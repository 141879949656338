define('uti-admin/routes/profiles/edit', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

		model: function model(params) {
			return this.store.findRecord('profile', params.p_id);
		},

		setupController: function setupController(controller, model) {
			controller.set('model', model);
			this.store.query('support-case', { profile: model.id }).then(function (s) {
				controller.set('s', s);
			});
			this.store.query('payment', { transaction: { contains: model.id } }).then(function (p) {
				controller.set('p', p);
			});
			this.store.query('user', { profile: model.id }).then(function (u) {
				controller.set('u', u);
			});
			this.store.query('questroom', { profile: model.id }).then(function (q) {
				controller.set('rooms', q);
			});
			this.store.query('adr', { profile: model.id }).then(function (q) {
				controller.set('adr', q);
			});
		},

		actions: {
			save: function save(model) {
				model.save();
			},

			'delete': function _delete(model) {
				model.destroyRecord();
			}
		}
	});
});
define('uti-admin/models/support-case', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    messages: _emberData['default'].hasMany('support', { async: true }),
    title: _emberData['default'].attr('string'),
    profile: _emberData['default'].attr('string'),
    closed: _emberData['default'].attr('boolean'),
    answered: _emberData['default'].attr('boolean'),
    css: (function () {
      if (!this.get('closed')) {
        if (this.get('answered')) {
          return 'success';
        } else {
          return 'open';
        }
      } else {
        return 'closed';
      }
    }).property('closed', 'answered')
  });
});
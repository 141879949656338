define('uti-admin/routes/support/faq', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		model: function model() {
			return this.store.findAll('faq');
		},
		setupController: function setupController(controller, model) {
			controller.set('model', model);
			this.controllerFor('application').set('title', 'Техподдержка');
		}
	});
});
define('uti-admin/models/adr', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        city: _emberData['default'].attr('string'),
        street: _emberData['default'].attr('string'),
        house: _emberData['default'].attr('string'),
        front: _emberData['default'].attr('string'),
        level: _emberData['default'].attr('string'),
        profile: _emberData['default'].attr('string'),
        office: _emberData['default'].attr('string'),
        isDelete: _emberData['default'].attr('boolean', { defaultValue: false }),
        comment: _emberData['default'].attr('string'),
        fullAdres: (function () {
            var str = '';
            str += this.get('front') ? ', подъезд ' + this.get('front') : '';
            str += this.get('level') ? ', этаж ' + this.get('level') : '';
            str += this.get('office') ? ', офис ' + this.get('office') : '';

            return this.get('city') + ', ' + this.get('street') + ', ' + this.get('house') + str;
        }).property('city,front,street,house,level,office')

    });
});
define('uti-admin/models/visit', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({

        createdAt: _emberData['default'].attr('string'),
        author: _emberData['default'].attr('string'),
        short: _emberData['default'].attr('string', { defaultValue: 'NA' }),
        title: _emberData['default'].attr('string'),
        manager: _emberData['default'].attr('string'),
        manager2: _emberData['default'].attr('string'),
        trafic: _emberData['default'].attr('string'), //откуда клиент узнал про нас
        timeLeft: _emberData['default'].attr('number'),
        players: _emberData['default'].attr('number'),
        defaultSum: _emberData['default'].attr('number', { defaultValue: 0 }),
        comment: _emberData['default'].attr('string'),
        comand: _emberData['default'].attr('string'),
        telephone: _emberData['default'].attr('string'),
        clientName: _emberData['default'].attr('string'),
        discount: _emberData['default'].attr('number'),
        date: _emberData['default'].attr('string'),
        dateEnd: _emberData['default'].attr('string'),
        visitsInform: _emberData['default'].attr('string'),
        isDelete: _emberData['default'].attr('boolean', { defaultValue: false }),
        status: _emberData['default'].attr('number', { defaultValue: 0 }),

        room: _emberData['default'].belongsTo('questroom', { async: true }),

        formatDate: (function () {
            return moment(this.get('date')).utc().format('L');
        }).property('date'),

        formatCreated: (function () {
            return moment(this.get('createdAt')).format('DD.MM.YYYY');
        }).property('createdAt'),

        time: (function () {
            return moment(this.get('date')).utc().format('LT');
        }).property('date'),

        outlet: (function () {
            return 'visits.show';
        }).property()

    });
});
define('uti-admin/models/global-client', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		vk: _emberData['default'].attr('string'),
		img: _emberData['default'].attr('string'),
		title: _emberData['default'].attr('string'),
		telephone: _emberData['default'].attr('string'),
		email: _emberData['default'].attr('string'),
		title: _emberData['default'].attr('string'),
		visits: _emberData['default'].hasMany('visit', { async: true }),
		favorites: _emberData['default'].hasMany('questroom', { async: true })
	});
});